<template>
  <popup-dialog title="学生认证" @close="close" v-if="visible">
    <div class="member-student" v-loading="loading">
      <!-- 审核成功 -->
      <el-result icon="success" title="审核成功" subTitle="您的资料已审核通过，可以购买对应会员权益" v-if="info.status==1"></el-result>
      <!-- 等待审核 -->
      <el-result icon="info" title="等待审核中..." subTitle="请耐心等待，我们会尽快处理" v-if="info.status==0"></el-result>
      <!-- 审核驳回 -->
      <el-result icon="error" title="审核驳回" :subTitle="info.remark ? '驳回原因：'+info.remark : '请上传真实有效证件信息'" v-if="info.status==2"></el-result>
      <el-form :model="subForm" :rules="rules" ref="subForm" label-width="130px" v-if="JSON.stringify(info) == '{}' || info.status==2">
        <el-form-item label="身份证号" prop="id_card_num">
          <el-input v-model="subForm.id_card_num" placeholder="请输入您的身份证号" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证正面上传" prop="card_up_img">
          <el-upload
              class="card-uploader"
              action="#"
              accept=".png,.jpg,.jpeg"
              :before-upload="beforeUpload"
              :http-request="cardUpImageUpload"
              list-type="picture-card"
              :show-file-list="false">
            <template v-if="subForm.card_up_img ">
              <img :src="subForm.card_up_img " class="avatar">
            </template>
            <template v-else>
              <i class="el-icon-upload avatar-uploader-icon"></i>
            </template>
            <div class="el-upload__tip" slot="tip">
              <span>请上传身份证文字面</span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="身份证反面上传" prop="card_down_img">
          <el-upload
              class="card-uploader"
              action="#"
              accept=".png,.jpg,.jpeg"
              :before-upload="beforeUpload"
              :http-request="cardDownImageUpload"
              list-type="picture-card"
              :show-file-list="false">
            <template v-if="subForm.card_down_img">
              <img :src="subForm.card_down_img" class="avatar">
            </template>
            <template v-else>
              <i class="el-icon-upload avatar-uploader-icon"></i>
            </template>
            <div class="el-upload__tip" slot="tip">
              <span>请上传身份证国徽面</span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="学生证扫描件" prop="student_id_card_img">
          <el-upload
              class="card-uploader"
              action="#"
              accept=".png,.jpg,.jpeg"
              :before-upload="beforeUpload"
              :http-request="cardStudentImageUpload"
              list-type="picture-card"
              :show-file-list="false">
            <template v-if="subForm.student_id_card_img">
              <img :src="subForm.student_id_card_img" class="avatar">
            </template>
            <template v-else>
              <i class="el-icon-upload avatar-uploader-icon"></i>
            </template>
            <div class="el-upload__tip" slot="tip">
              <span>请上传学生证扫描件</span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item align="right">
          <el-button type="danger" :loading="subLoading" @click="saveForm">提 交</el-button>
          <el-button :loading="subLoading" @click="close">取 消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </popup-dialog>
</template>

<script>
import { vipExamine, getVipExamine } from "@/api/index";
import { getQiNiuToken, addFiles } from "@/api/upload";
import * as qiniu from "qiniu-js";
import PopupDialog from "@/components/popupDialog";
export default {
  name: "MemberStudent",
  components: {
    PopupDialog
  },
  data() {
    return {
      visible: false,
      loading: false,
      subLoading: false,
      info: {},
      subForm: {
        // 身份证号
        id_card_num: "",
        // 身份证正面图片ID
        card_up: 0,
        // 身份证正面图片地址
        card_up_img: "",
        // 身份证反面图片ID
        card_down: 0,
        // 身份证反面图片地址
        card_down_img: "",
        // 学生证图片ID
        student_id_card_img_id: 0,
        // 学生证图片地址
        student_id_card_img: ""
      },
      rules: {
        id_card_num: [
          { required: true, message: '请输入您的身份证号', trigger: 'blur' }
        ],
        card_up_img: [
          { required: true, message: '请上传身份证文字正面扫描件', trigger: 'blur' }
        ],
        card_down_img: [
          { required: true, message: '请上传身份证国徽反面扫描件', trigger: 'blur' }
        ],
        student_id_card_img: [
          { required: true, message: '请上传学生证扫描件', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    // 打开窗口
    open() {
      this.getVipExamineStatus();
      this.visible = true;
    },
    // 关闭窗口
    close() {
      this.visible = false;
    },
    // 查询VIP审核状态
    async getVipExamineStatus() {
      this.loading = true;
      let res = await getVipExamine();
      if (res && res.code == 200) {
        // 没有填写值是 {}
        this.info = res.data;
      }
      this.loading = false;
    },
    // 绑定表单
    bindData() {
      if (JSON.stringify(this.info) !="{}") {
        this.subForm.id_card_num = this.info.id_card_num;
        if (this.info.id_card_imgs && this.info.id_card_imgs.length == 2) {
          this.subForm.card_up_img = this.info.id_card_imgs[0]['img'];
          this.subForm.card_down_img = this.info.id_card_imgs[1]['img'];
        }
        if (this.info.student_id_card_img && this.info.student_id_card_img.img) {
          this.subForm.student_id_card_img = this.info.student_id_card_img.img;
        }
      } else {
        this.subForm = {
          // 身份证号
          id_card_num: "",
          // 身份证正面图片ID
          card_up: 0,
          // 身份证正面图片地址
          card_up_img: "",
          // 身份证反面图片ID
          card_down: 0,
          // 身份证反面图片地址
          card_down_img: "",
          // 学生证图片ID
          student_id_card_img_id: 0,
          // 学生证图片地址
          student_id_card_img: ""
        };
      }
    },
    // 图片文件上传前
    beforeUpload(file) {
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      if (isJPEG || isJPG || isPNG) {
        return true;
      } else {
        this.$baseMessage("请上传jpg、jpeg、png图片格式的文件！", "error");
        return false;
      }
    },
    // 身份证正面图片上传
    async cardUpImageUpload(e) {
      let _This = this;
      this.subLoading = true;
      let res = await getQiNiuToken();
      if (res && res.code == 200) {
        const {v4} = require('uuid');
        let key = v4() + e.file.uid + e.file.name.substr(e.file.name.lastIndexOf("."));
        let observe = {
          async complete(res) {
            let resadd = await addFiles([{
              img: "/" + res.key,
              name: `请上传您的身份证正面`,
              type: 1,
              size: e.file.size,
              group_id: 0,
            }]);
            if (resadd && resadd.code == 200) {
              _This.subForm.card_up = resadd.data[0].id;
              _This.subForm.card_up_img = resadd.data[0].img;
            }
            _This.subLoading = false;
          }
        };
        //开始上传  token 需要找后端获取(单独的方法)
        let observable = qiniu.upload(e.file, key, res.data.uploadToken, {}, {});
        observable.subscribe(observe);
      }
    },
    // 身份证反面图片上传
    async cardDownImageUpload(e) {
      let _This = this;
      this.subLoading = true;
      let res = await getQiNiuToken();
      if (res && res.code == 200) {
        const {v4} = require('uuid');
        let key = v4() + e.file.uid + e.file.name.substr(e.file.name.lastIndexOf("."));
        let observe = {
          async complete(res) {
            let resadd = await addFiles([{
              img: "/" + res.key,
              name: `请上传您的身份证反面`,
              type: 1,
              size: e.file.size,
              group_id: 0,
            }]);
            if (resadd && resadd.code == 200) {
              _This.subForm.card_down = resadd.data[0].id;
              _This.subForm.card_down_img = resadd.data[0].img;
            }
            _This.subLoading = false;
          }
        };
        //开始上传  token 需要找后端获取(单独的方法)
        let observable = qiniu.upload(e.file, key, res.data.uploadToken, {}, {});
        observable.subscribe(observe);
      }
    },
    // 学生证扫描件图片上传
    async cardStudentImageUpload(e) {
      let _This = this;
      this.subLoading = true;
      let res = await getQiNiuToken();
      if (res && res.code == 200) {
        const {v4} = require('uuid');
        let key = v4() + e.file.uid + e.file.name.substr(e.file.name.lastIndexOf("."));
        let observe = {
          async complete(res) {
            let resadd = await addFiles([{
              img: "/" + res.key,
              name: `请上传您的学生证扫描件`,
              type: 1,
              size: e.file.size,
              group_id: 0,
            }]);
            if (resadd && resadd.code == 200) {
              _This.subForm.student_id_card_img_id = resadd.data[0].id;
              _This.subForm.student_id_card_img = resadd.data[0].img;
            }
            _This.subLoading = false;
          }
        };
        //开始上传  token 需要找后端获取(单独的方法)
        let observable = qiniu.upload(e.file, key, res.data.uploadToken, {}, {});
        observable.subscribe(observe);
      }
    },
    // 数据处理
    submitHandle() {
      let param = JSON.parse(JSON.stringify(this.subForm));
      param['id_card_img_ids'] = `${param.card_up},${param.card_down}`;
      delete param.card_up_img;
      delete param.card_down_img;
      delete param.student_id_card_img;
      delete param.card_up;
      delete param.card_down;
      return param;
    },
    // 保存
    saveForm() {
      this.$refs.subForm.validate(async (valid) => {
        if (valid) {
          this.subLoading = true;
          let temp_sub = this.submitHandle();
          let res = await vipExamine(temp_sub);
          if (res && res.code == 200) {
            this.$message.success(res.msg ? res.msg : "操作成功");
            this.close();
          } else {
            this.$message.error(res && res.msg ? res.msg : "操作失败，请稍后重试");
          }
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.card-uploader {
  ::v-deep .el-upload {
    max-width: 250px;
    max-height: 120px;
    border: 1px dashed #d9d9d9;

    .avatar {
      max-width: 100%;
      max-height: 100px;
    }
  }

  .avatar-uploader-icon {
    padding-top: 15px;
    font-size: 30px;
    color: #8c939d;
    text-align: center;
  }

  .el-upload__tip {
    margin: 0;
    color: #909399;
  }
}
::v-deep {
  .popup-wrap {
    width: 500px !important;
    &-content {
      margin-top: 30px !important;
    }
  }
  .el-button--danger {
    background-color: #ed6f33;
    border-color: #ed6f33;
    &:hover, &:active {
      background-color: #f7844d;
      border-color: #f7844d;
    }
  }
  .el-button--default {
    &:hover, &:active {
      color: #ed6f33;
      background-color: #f9d1be;
      border-color: #f7844d;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  ::v-deep {
    .popup-wrap {
      width: 90% !important;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  ::v-deep {
    .popup-wrap {
      width: 90% !important;
    }
  }
}
</style>