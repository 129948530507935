<template>
  <popup-dialog title="会员服务协议" @close="close" v-if="visible">
    <div v-html="content"></div>
  </popup-dialog>
</template>

<script>
import PopupDialog from "@/components/popupDialog";
export default {
  name: "serviceAgreement",
  components: {
    PopupDialog
  },
  data() {
    return {
      content: "",
      visible: false,
    }
  },
  methods: {
    open(e) {
      this.content = e;
      this.visible = true;
    },
    close() {
      this.visible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .popup-wrap {
    width: 800px !important;
    &-content {
      margin-top: 30px !important;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  ::v-deep {
    .popup-wrap {
      width: 90% !important;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  ::v-deep {
    .popup-wrap {
      width: 90% !important;
    }
  }
}
</style>